<template>
  <div class="advan">
    <div class="advan__desc" v-if="pageText">
      <EditorJSComponent :text="pageText" />
    </div>
    <h2 class="advan__title" v-if="advantages.length">Почему Лизинг-Трейд?</h2>
    <div class="advan__list" id="flickity_advantages" :class="{ 'flickity-ready': flickityReady }">
      <div v-for="(advantage, index) in advantages" :key="index" class="advan__list__item">
        <i class="icon" v-html="advantage.icon"></i>
        <h3 class="advan__list__title">{{ advantage.title }}</h3>
        <div class="advan__list__desc">
          <EditorJSComponent :text="getAdvantagesDescription(advantage)" />
        </div>
      </div>
    </div>
    <div class="advan__actions">
      <router-link :to="{ name: 'request' }" class="btn">Отправить заявку</router-link>
      <router-link :to="{ name: 'calculator' }" class="btn-dark">Расчитать лизинг</router-link>
    </div>
  </div>
</template>

<script>
import EditorJSComponent from "components/EditorJSComponent.vue";

export default {
  name: "AboutAdvantagesComponent",
  data() {
    return {
      flickityReady: false,
      flickity: undefined,
    };
  },
  computed: {
    pageText() {
      if (this.pageData && this.pageData.page_texts) {
        const text = this.pageData.page_texts.find((data) => data.name === "advantages");
        if (text && text.text) {
          const parsedText = JSON.parse(text.text);
          return parsedText && parsedText.blocks && parsedText.blocks.length ? parsedText : false;
        }
      }
      return false;
    },
    pageData() {
      if (this.$store.state.about_page && this.$store.state.about_page.page) {
        return this.$store.state.about_page.page;
      } else {
        return undefined;
      }
    },
    advantages() {
      if (
        this.$store.state.about_page &&
        this.$store.state.about_page.advantages &&
        this.$store.state.about_page.advantages.length
      ) {
        return this.$store.state.about_page.advantages.map((adv) => adv.advantage);
      } else {
        return [];
      }
    },
  },
  mounted() {
    if (document.getElementById("flickity_advantages") && (this.$mq === "768" || this.$mq === "560")) {
      const Flickity = require("flickity");
      this.flickity = new Flickity("#flickity_advantages", {
        prevNextButtons: false,
        pageDots: false,
        cellAlign: "left",
        contain: true,
        groupCells: false,
        on: {
          ready: () => {
            this.flickityReady = true;
          },
        },
      });
    }
  },
  methods: {
    getAdvantagesDescription(adv) {
      if (adv.description) {
        let haveError = false;
        try {
          JSON.parse(adv.description);
        } catch (e) {
          haveError = true;
        }
        if (!haveError) {
          return JSON.parse(adv.description);
        } else {
          return {
            blocks: [
              {
                type: "paragraph",
                data: { text: adv.description },
              },
            ],
          };
        }
      }
      return {
        blocks: [],
      };
    },
  },
  components: {
    EditorJSComponent,
  },
};
</script>

<style lang="stylus">
@import "~@/styles/global/global.styl"

.advan {
  display flex
  flex-direction column
  align-items flex-start
  justify-content flex-start
  padding 30px 0 60px
  width 100%

  &__title {
    font-weight: bold;
    font-size: 16px;
    line-height: 26px;
    color: main_black;
    text-transform uppercase
    margin 30px 0
  }

  &__desc {
    font-weight: normal;
    font-size: 20px;
    line-height: 34px;
    color: main_dark;
    max-width 840px
    width 100%
    margin 0
  }

  &__list {
    display flex
    flex-wrap wrap
    margin "-%s -%s" % (margin margin)
    width "calc(100% + (%s * 2))" % margin
    +below(768px) {
      display block
      margin 0
      width 100%
      outline none
    }

    &__item {
      display flex
      flex-direction column
      justify-content center
      align-items center
      text-align center
      background: main_white;
      border: 1px solid main_gray;
      box-sizing: border-box;
      border-radius: radius;
      padding 30px 40px 35px
      margin margin
      flex-basis: "calc(33.333333% - (%s * 2))" % margin
      flex-grow: 1;
      min-height 90%
      transition all 0.3s
      +below(1150px) {
        flex-basis: "calc(50% - (%s * 2))" % margin
      }
      +below(768px) {
        flex-basis: initial
        width 58.3333333%
        padding margin
      }
      +below(560px) {
        width 83.3333333%
      }
      +below(420px) {
        width 91.6666667%
      }
      +below(360px) {
        width 100%
      }

      &:hover {
        border-color main_color
      }

      i {
        width 80px
        height 80px
        display flex
        justify-content center
        align-items center

        svg {
          fill main_color
          width 100%
          height 100%
          max-width 80px
          max-height 80px
        }
      }
    }

    &__title {
      font-weight: bold;
      font-size: 22px;
      line-height: 27px;
      text-align: center;
      color: main_dark;
      margin 15px 0
    }

    &__desc {
      font-weight: normal;
      font-size: 16px;
      line-height: 26px;
      text-align: center;
      color: main_dark;
      margin 0
    }
  }

  &__actions {
    display flex
    justify-content center
    align-items center
    flex-flow wrap
    margin "30px -%s -%s" % (margin margin)
    width "calc(100% + (%s * 2))" % margin

    a
    button {
      margin margin
    }
  }

  &__tooltip {
    background: main_dark;
    box-shadow: 0 15px 30px rgba(main_black, 0.3);
    border-radius: radius;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: main_white;
    padding 10px
  }
}
</style>
