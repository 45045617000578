<template>
  <div class="rqs" v-if="requisites && requisites.value">
    <EditorJSComponent :text="JSON.parse(requisites.value)" />
  </div>
</template>

<script>
import EditorJSComponent from "components/EditorJSComponent.vue";

export default {
  name: "AboutRequisitesComponent",
  components: { EditorJSComponent },
  computed: {
    requisites() {
      if (this.$store.state.about_page && this.$store.state.about_page.requisites) {
        return this.$store.state.about_page.requisites;
      } else {
        return undefined;
      }
    },
  },
};
</script>

<style lang="stylus">
.rqs {
  display flex
  flex-direction column
  align-items flex-start
  justify-content flex-start
  margin 50px 0 60px

  .editor-js {
    width 100%

    table tbody tr td:last-child {
      width 60%
    }
  }
}
</style>
