<template>
  <i class="download-document-icon icon">
    <svg width="16" height="24" viewBox="0 0 16 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.33333 6.375V0H1C0.445833 0 0 0.501562 0 1.125V22.875C0 23.4984 0.445833 24 1 24H15C15.5542 24 16 23.4984 16 22.875V7.5H10.3333C9.78333 7.5 9.33333 6.99375 9.33333 6.375ZM12.5188 16.2825L8.50125 20.7684C8.22417 21.0783 7.77667 21.0783 7.49958 20.7684L3.48208 16.2825C3.05917 15.8105 3.35583 15 3.95083 15H6.66667V11.25C6.66667 10.8356 6.965 10.5 7.33333 10.5H8.66667C9.035 10.5 9.33333 10.8356 9.33333 11.25V15H12.0492C12.6442 15 12.9408 15.8105 12.5188 16.2825ZM15.7083 4.92188L11.6292 0.328125C11.4417 0.117188 11.1875 0 10.9208 0H10.6667V6H16V5.71406C16 5.41875 15.8958 5.13281 15.7083 4.92188Z"
        fill="#2A3742"
      />
    </svg>
  </i>
</template>

<script>
export default {
  name: "DownloadDocumentIcon",
};
</script>
