<template>
  <div class="inv">
    <div class="inv__desc" v-if="pageText">
      <EditorJSComponent :text="pageText" />
    </div>
  </div>
</template>

<script>
import EditorJSComponent from "components/EditorJSComponent.vue";

export default {
  name: "AboutBecomeInvestorsComponent",
  components: {
    EditorJSComponent,
  },
  data() {
    return {
      activeTab: 0,
    };
  },
  computed: {
    pageText() {
      if (this.pageData && this.pageData.page_texts) {
        const text = this.pageData.page_texts.find((data) => data.name === "investors");
        if (text && text.text) {
          const parsedText = JSON.parse(text.text);
          return parsedText && parsedText.blocks && parsedText.blocks.length ? parsedText : false;
        }
      }
      return false;
    },
    pageData() {
      if (this.$store.state.about_page && this.$store.state.about_page.page) {
        return this.$store.state.about_page.page;
      } else {
        return undefined;
      }
    },
  },
};
</script>
