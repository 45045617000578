<template>
  <main class="main">
    <section class="container">
      <BreadcrumbsComponent :title="tabs[activeTab] ? tabs[activeTab].title : 'О компании'" />
      <div class="main__text" v-if="pageText">
        <EditorJSComponent :text="pageText" />
      </div>
      <div class="tabs" v-if="mounted">
        <div class="tabs__list" id="flickity_tabs" :class="{ 'flickity-ready': flickityReady }">
          <router-link
            class="tabs__list__item"
            v-show="tab.show"
            v-for="(tab, index) in tabs"
            :key="index"
            :to="{ name: 'about', hash: tab.hash }"
            :class="{ active: activeTab === index }"
          >
            {{ tab.title }}
          </router-link>
        </div>
      </div>
      <div class="tab-content">
        <AboutAdvantagesComponent v-show="activeTab === 0" />
        <AboutManagementComponent v-show="activeTab === 1" />
        <AboutInvestorsComponent v-show="activeTab === 2" />
        <AboutRequisitesComponent v-show="activeTab === 3" />
        <AboutBecomeInvestorsComponent v-show="activeTab === 4" />
      </div>
    </section>
  </main>
</template>

<script>
import BreadcrumbsComponent from "components/BreadcrumbsComponent.vue";
import AboutAdvantagesComponent from "@/views/about/components/Advantages.vue";
import AboutInvestorsComponent from "@/views/about/components/Investors.vue";
import AboutManagementComponent from "@/views/about/components/Management.vue";
import AboutRequisitesComponent from "@/views/about/components/Requisites.vue";
import EditorJSComponent from "components/EditorJSComponent.vue";
import AboutBecomeInvestorsComponent from "@/views/about/components/BecomeInvestor.vue";
import { cityIn } from "lvovich";

export default {
  name: "AboutPage",
  async asyncData({ store, link }) {
    store.state.alternateLink = link;
    await store.dispatch("GET_ABOUT_PAGE");
  },
  data() {
    return {
      mounted: false,
      flickityReady: false,
      flickity: undefined,
    };
  },
  computed: {
    pageText() {
      if (this.pageData && this.pageData.page_texts) {
        const text = this.pageData.page_texts.find((data) => data.name === "description");
        if (text && text.text) {
          const parsedText = JSON.parse(text.text);
          return parsedText && parsedText.blocks && parsedText.blocks.length ? parsedText : false;
        }
      }
      return false;
    },
    pageTitle() {
      let page = {};
      let city = this.$store.state.cities.find((c) => c.title_eng === this.$store.state.geoData.city);
      city = cityIn(city ? city.title : "Казань");
      if (this.$store.state.about_page && this.$store.state.about_page.page) {
        page = this.$store.state.about_page.page;
      }
      return (page.title || page.meta_title).replace(/{{ city }}/g, `в ${city}`);
    },
    pageData() {
      if (this.$store.state.about_page && this.$store.state.about_page.page) {
        return this.$store.state.about_page.page;
      } else {
        return undefined;
      }
    },
    tabs() {
      let advantages = [];
      if (
        this.$store.state.about_page &&
        this.$store.state.about_page.advantages &&
        this.$store.state.about_page.advantages.length
      ) {
        advantages = this.$store.state.about_page.advantages;
      }
      let management = [];
      if (
        this.$store.state.about_page &&
        this.$store.state.about_page.management &&
        this.$store.state.about_page.management.length
      ) {
        management = this.$store.state.about_page.management;
      }
      let documents = [];
      if (
        this.$store.state.about_page &&
        this.$store.state.about_page.documents &&
        this.$store.state.about_page.documents.length
      ) {
        documents = this.$store.state.about_page.documents.filter(
          (d) => d.section && d.section.code === this.$store.state.type.CODE_DOCUMENT_INVEST
        );
      }
      let charts = [];
      if (
        this.$store.state.about_page &&
        this.$store.state.about_page.charts &&
        this.$store.state.about_page.charts.length
      ) {
        charts = this.$store.state.about_page.charts;
      }
      let requisites;
      if (this.$store.state.about_page && this.$store.state.about_page.requisites) {
        requisites = this.$store.state.about_page.requisites;
      }
      let becomeInvestor;
      if (
        this.$store.state.about_page &&
        this.$store.state.about_page.page &&
        this.$store.state.about_page.page.page_texts
      ) {
        const text = this.$store.state.about_page.page.page_texts.find((data) => data.name === "investors");
        if (text && text.text) {
          const parsedText = JSON.parse(text.text);
          becomeInvestor = parsedText && parsedText.blocks && parsedText.blocks.length ? parsedText : false;
        }
      }
      return [
        {
          show: !!advantages.length,
          hash: "#advantages",
          title: this.pageTitle,
        },
        {
          show: !!management.length,
          hash: "#management",
          title: "Руководство",
        },
        {
          show: !!documents.length || becomeInvestor !== undefined || !!charts.length,
          hash: "#investors",
          title: "Инвесторам",
        },
        {
          show: requisites !== undefined,
          hash: "#requisites",
          title: "Реквизиты",
        },
      ];
    },
    activeTab() {
      if (this.$route.hash) {
        if (this.tabs.find((tab) => tab.hash === this.$route.hash).show) {
          return this.tabs.findIndex((tab) => tab.hash === this.$route.hash);
        }
      }
      return this.tabs.findIndex((tab) => tab.show === true);
    },
  },
  mounted() {
    this.mounted = true;
    if (document.getElementById("flickity_tabs")) {
      const Flickity = require("flickity");
      this.flickity = new Flickity("#flickity_tabs", {
        prevNextButtons: false,
        pageDots: false,
        cellAlign: "left",
        contain: true,
        on: {
          ready: () => {
            this.flickityReady = true;
          },
        },
      });
      this.flickity.select(this.activeTab);
    }
  },
  methods: {
    getImg(item) {
      if (item.head_img && item.head_img.img && item.head_img.img.url) {
        return this.$store.state.api + item.head_img.img.url;
      }
    },
  },
  metaInfo() {
    let activeTab = this.tabs[this.activeTab];
    let api = this.$store.state.api;
    let data = {};
    let alternateLink = this.$store.state.alternateLink;
    let city = this.$store.state.cities.find((c) => c.title_eng === this.$store.state.geoData.city);
    city = cityIn(city ? city.title : "Казань");
    if (this.$store.state.about_page && this.$store.state.about_page.page) {
      data = this.$store.state.about_page.page;
    }
    const title = (data.meta_title || data.title).replace(/{{ city }}/g, `в ${city}`);
    return {
      title,
      script: [
        {
          type: "application/ld+json",
          json: {
            "@context": "https://schema.org",
            "@type": "WebPage",
            name: title,
            description: (data.meta_keywords || "").replace(/{{ city }}/g, `в ${city}`),
          },
        },
        {
          type: "application/ld+json",
          json: {
            "@context": "http://www.schema.org",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: "1",
                name: "Главная",
                item: api,
              },
              {
                "@type": "ListItem",
                position: "2",
                name: activeTab.title,
                item: api + activeTab.hash,
              },
            ],
          },
        },
      ],
      link: [
        { rel: "canonical", href: alternateLink },
        { rel: "alternate", hreflang: "x-default", href: alternateLink },
      ],
      meta: [
        {
          vmid: "keywords",
          name: "keywords",
          content: (data.meta_keywords || "").replace(/{{ city }}/g, `в ${city}`),
        },
        {
          vmid: "description",
          name: "description",
          content: (data.meta_keywords || "").replace(/{{ city }}/g, `в ${city}`),
        },
        {
          vmid: "og:title",
          name: "og:title",
          content: title,
        },
        {
          vmid: "og:image",
          name: "og:image",
          content: "",
        },
        {
          vmid: "og:description",
          name: "og:description",
          content: (data.meta_keywords || "").replace(/{{ city }}/g, `в ${city}`),
        },
      ],
    };
  },
  components: {
    AboutBecomeInvestorsComponent,
    EditorJSComponent,
    AboutAdvantagesComponent,
    AboutRequisitesComponent,
    AboutManagementComponent,
    AboutInvestorsComponent,
    BreadcrumbsComponent,
  },
};
</script>

<style lang="stylus">
@import "~@/styles/global/global.styl"
@import "~@/styles/parts/flickity.styl"
.tabs {
  width 100%
  margin-top 14px

  &__list {
    outline none
    width 100%
    list-style none
    border-bottom 1px solid main_gray

    &__item {
      display inline-flex
      align-items center
      justify-content center
      flex-shrink 0
      box-sizing border-box
      padding 14px 20px
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      color main_dark
      text-align: center;

      &.active {
        color: main_color;
        border-bottom 3px solid main_dark
      }
    }
  }
}

.tab-content {
  width 100%
}
</style>
