<template>
  <div class="inv">
    <ChartsList />
    <div class="inv__tabs" v-if="showDocsTab">
      <ul class="inv__tabs__list">
        <template v-for="(type, index) in documentTypes">
          <li :class="{ active: activeTab.id === type.id }" @click="setActiveType(type)" :key="index">
            <i class="icon" v-html="type.icon"></i>
            <span>{{ type.title }}</span>
          </li>
        </template>
        <li :class="{ active: activeTab.id === 'invest' }" @click="activeTab.id = 'invest'" v-if="pageText">
          <BlackPortfolio />
          <span>Стать инвестором</span>
        </li>
      </ul>
      <div class="inv__tabs__content">
        <EditorJSComponent :text="pageText" v-if="activeTab.id === 'invest'" />
        <template v-else-if="activeTab.subtype && activeTab.subtype.length">
          <div class="docs__tabs-container">
            <div class="docs__tabs">
              <a
                href="#"
                v-for="(s, i) in activeTab.subtype"
                @click.prevent="activeSubtype = JSON.parse(JSON.stringify(s))"
                :key="i"
                class="docs__tabs-item"
                :class="{
                  'docs__tabs-item--active': activeSubtype.id === s.id,
                }"
              >
                {{ s.title }}
              </a>
            </div>
          </div>
          <div class="docs__list">
            <a
              :href="$store.state.api + doc.url"
              class="docs__list__item"
              v-for="(doc, i) in documentsNoYear"
              :key="i"
              :download="doc.title"
              target="_blank"
            >
              <DownloadDocumentIcon v-if="!doc.icon" />
              <i class="icon" v-else v-html="doc.icon"></i>
              <div class="docs__list__container">
                <span class="docs__list__title">{{ doc.title }}</span>
                <span class="docs__list__subtitle">{{ doc.subtitle }}</span>
              </div>
            </a>
          </div>
        </template>
        <ul class="docs" v-else>
          <li class="docs__item" v-for="(year, index) in documents" :key="index">
            <span class="docs__title" v-if="year.year">{{ year.year }} год</span>
            <div class="docs__list">
              <a
                :href="$store.state.api + doc.url"
                class="docs__list__item"
                v-for="(doc, i) in year.docs.filter((d) => d.type.id === activeTab.id)"
                :key="i"
                :download="doc.title"
                target="_blank"
                :class="{ 'docs__list--one': i === 0 }"
              >
                <DownloadDocumentIcon v-if="!doc.icon" />
                <i class="icon" v-else v-html="doc.icon"></i>
                <div class="docs__list__container">
                  <span class="docs__list__title">{{ doc.title }}</span>
                  <span class="docs__list__subtitle">{{ doc.subtitle }}</span>
                </div>
              </a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import DownloadDocumentIcon from "components/svg/DownloadDocument.vue";
import ChartsList from "@/views/about/components/ChartsList.vue";
import EditorJSComponent from "components/EditorJSComponent.vue";
import BlackPortfolio from "components/svg/BlackPortfolio.vue";

export default {
  name: "AboutInvestorsComponent",
  components: {
    BlackPortfolio,
    EditorJSComponent,
    ChartsList,
    DownloadDocumentIcon,
  },
  data() {
    return {
      activeSubtype: {
        id: 0,
      },
      activeTab: {
        id: 0,
      },
    };
  },
  computed: {
    pageText() {
      if (this.pageData && this.pageData.page_texts) {
        const text = this.pageData.page_texts.find((data) => data.name === "investors");
        if (text && text.text) {
          const parsedText = JSON.parse(text.text);
          return parsedText && parsedText.blocks && parsedText.blocks.length ? parsedText : false;
        }
      }
      return false;
    },
    pageData() {
      if (this.$store.state.about_page && this.$store.state.about_page.page) {
        return this.$store.state.about_page.page;
      } else {
        return undefined;
      }
    },
    showDocsTab() {
      return this.documentTypes.filter(
        (t) => this.documentsRaw.filter((d) => d.type && d.type.id === t.id).length
      ).length;
    },
    documentTypes() {
      if (
        this.$store.state.about_page &&
        this.$store.state.about_page.document_types &&
        this.$store.state.about_page.document_types.length
      ) {
        return this.$store.state.about_page.document_types.filter((t) => {
          return this.documentsRaw.filter((d) => d.type && d.type.id === t.id && d.year).length;
        });
      } else {
        return [];
      }
    },
    documentsRaw() {
      if (
        this.$store.state.about_page &&
        this.$store.state.about_page.documents &&
        this.$store.state.about_page.documents.length
      ) {
        return this.$store.state.about_page.documents;
      } else {
        return [];
      }
    },
    documentsNoYear() {
      if (
        this.$store.state.about_page &&
        this.$store.state.about_page.documents &&
        this.$store.state.about_page.documents.length
      ) {
        const documents = JSON.parse(JSON.stringify(this.$store.state.about_page.documents));
        return documents
          .sort((a, b) => {
            return (a.position || 0) - (b.position || 0);
          })
          .filter((d) => {
            const isDocOfActiveType = d.type && d.type.id === this.activeTab.id;
            if (this.activeTab.subtype && this.activeTab.subtype.length) {
              const isDocOfActiveSubtype = d.subtype && d.subtype.id === this.activeSubtype.id;
              return isDocOfActiveType && isDocOfActiveSubtype;
            }
            return isDocOfActiveType;
          })
          .sort((a, b) => {
            return (b.year || 0) - (a.year || 0);
          });
      } else {
        return [];
      }
    },
    documents() {
      if (
        this.$store.state.about_page &&
        this.$store.state.about_page.documents &&
        this.$store.state.about_page.documents.length
      ) {
        const documents = this.$store.state.about_page.documents;
        const years = [...new Set(documents.map((doc) => doc.year))];
        let docs = [];
        years.forEach((year) => {
          docs.push({
            year: year,
            docs: documents
              .filter((document) => document.year === year)
              .sort((a, b) => {
                return (a.position || 0) - (b.position || 0);
              }),
          });
        });
        docs = docs.sort((a, b) => parseInt(b.year) - parseInt(a.year));
        return docs
          .filter((doc) => doc.year)
          .filter((doc) => {
            return doc.docs.filter((d) => {
              const isDocOfActiveType = d.type && d.type.id === this.activeTab.id;
              if (this.activeTab.subtype && this.activeTab.subtype.length) {
                const isDocOfActiveSubtype = d.subtype && d.subtype.id === this.activeSubtype.id;
                return isDocOfActiveType && isDocOfActiveSubtype;
              }
              return isDocOfActiveType;
            }).length;
          });
      } else {
        return [];
      }
    },
  },
  mounted() {
    if (this.documentTypes.length) {
      const types = this.documentTypes.filter(
        (t) => this.documentsRaw.filter((d) => d.type && d.type.id === t.id).length
      );
      if (types.length) {
        this.setActiveType(types[0]);
      }
    }
  },
  methods: {
    setActiveType(type) {
      this.activeTab = JSON.parse(JSON.stringify(type));
      if (this.activeTab.subtype && this.activeTab.subtype.length) {
        this.activeSubtype = JSON.parse(JSON.stringify(this.activeTab.subtype[0]));
      }
    },
  },
};
</script>

<style lang="stylus">
@import "~@/styles/global/global.styl"
@import "~@/styles/parts/docs.styl"
@import "~@/styles/parts/inv.styl"
</style>
