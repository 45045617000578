<template>
  <div class="mng" v-if="managements.length">
    <ul class="mng__list">
      <li v-for="(management, index) in managements" :key="index">
        <a @click.prevent class="mng__item">
          <img
            loading="lazy"
            decoding="async"
            :src="getImg(management)"
            :alt="getImgAlt(management)"
            class="mng__image"
          />
          <span class="mng__name"
            >{{ management.surname || "" }} {{ management.name || "" }}
            {{ management.patronymic || "" }}</span
          >
          <span class="mng__post">{{ management.held_post }}</span>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "AboutManagementComponent",
  computed: {
    managements() {
      if (
        this.$store.state.about_page &&
        this.$store.state.about_page.management &&
        this.$store.state.about_page.management.length
      ) {
        return this.$store.state.about_page.management;
      } else {
        return [];
      }
    },
  },
  methods: {
    getImg(management) {
      if (management.head_img && management.head_img.img && management.head_img.img.url) {
        return this.$store.state.api + management.head_img.img.url;
      } else {
        return "/public/images/avatar.jpg";
      }
    },
    getImgAlt(item) {
      if (item.head_img && item.head_img.img && item.head_img.img.alt) {
        return item.head_img.img.alt;
      }
    },
  },
};
</script>

<style lang="stylus">
@import "~@/styles/global/global.styl"
.mng {
  max-width 950px
  width 100%
  margin 0 auto

  &__item {
    display flex
    flex-direction column
    justify-content flex-start
    align-items flex-start
    +below(420px) {
      align-items center
    }
  }

  &__list {
    display flex
    justify-content center
    flex-wrap wrap
    margin "30px -%s" % margin
    width "calc(100% + (%s * 2))" % margin
    list-style none
    +below(950px) {
      justify-content flex-start
    }

    li {
      box-sizing: border-box;
      margin margin
      flex-basis: "calc(33.333333% - (%s * 2))" % margin
      +below(950px) {
        flex-basis: "calc(25% - (%s * 2))" % margin
      }
      +below(800px) {
        flex-basis: "calc(33.333333% - (%s * 2))" % margin
      }
      +below(620px) {
        flex-basis: "calc(50% - (%s * 2))" % margin
      }
      +below(420px) {
        flex-basis: "calc(100% - (%s * 2))" % margin
      }
    }
  }

  &__image {
    background: main_gray;
    border-radius: radius;
    width 290px
    height 290px
    margin-bottom 15px
    +below(950px) {
      width 177px
      height 177px
    }
    +below(420px) {
      width 290px
      height 290px
    }
  }

  &__name {
    font-weight: bold;
    font-size: 18px;
    line-height: 28px;
    color: main_dark;
    +below(440px) {
      font-size: 16px;
      line-height: 26px;
    }
  }

  &__post {
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    color: rgba(main_dark, 0.5);
    +below(440px) {
      font-size: 14px;
      line-height: 24px;
    }
  }
}
</style>
