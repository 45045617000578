<template>
  <i class="black-portfolio icon">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 448">
      <path
        d="M416 96h-96V48c0-8.8-7.2-16-16-16H144c-8.8 0-16 7.2-16 16v48H32c-17.7 0-32 14.3-32 32v256c0 17.7 14.3 32 32 32h384c17.7 0 32-14.3 32-32V128C448 110.3 433.7 96 416 96zM288 96H160V64h128V96z"
      ></path>
    </svg>
  </i>
</template>

<script>
export default {
  name: "BlackPortfolio",
};
</script>
