<template>
  <div class="inv__list">
    <div class="inv__item" v-for="(chart, index) in charts" :key="index">
      <h2 class="inv__item__title">{{ chart.title }}</h2>
      <div class="inv__item__chart">
        <ClientOnly>
          <VueApexCharts
            height="350"
            :options="chart.options"
            :series="chart.series"
            :class="'chart-' + chart.type_id"
          ></VueApexCharts>
        </ClientOnly>
      </div>
      <div class="inv__item__footnotes" v-if="getFootnotes(chart).length">
        <span v-for="(footnote, i) in getFootnotes(chart)" :key="i">{{ footnote }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import ClientOnly from "vue-client-only";

export default {
  name: "ChartsList",
  computed: {
    charts() {
      if (
        this.$store.state.about_page &&
        this.$store.state.about_page.charts &&
        this.$store.state.about_page.charts.length
      ) {
        return this.$store.state.about_page.charts.map((chart) => {
          chart.options = this.getChartOptions(chart);
          chart.series = this.getChartSeries(chart);
          console.log(chart.series);
          return chart;
        });
      } else {
        return [];
      }
    },
  },
  methods: {
    getFootnotes(chart) {
      if (chart.indicators && chart.indicators.length) {
        let indicators = chart.indicators
          .filter((ind) => ind.title)
          .map((ind) => {
            return ind.values.filter((val) => !!val.footnote).map((val) => val.footnote);
          })
          .flat();
        return [...indicators];
      }
      return [];
    },
    getChartSeries(chart) {
      switch (chart.type.code) {
        case this.$store.state.type.CODE_BUBBLE:
          if (chart.indicators.length) {
            return chart.indicators
              .filter((ind) => ind.title)
              .map((ind) => ({
                name: ind.title,
                data: ind.values
                  .map((val) => ({
                    x: parseFloat(val.value ?? 0),
                    y: parseInt(val.year ?? 0),
                    z: parseInt(val.z ?? 0),
                  }))
                  .filter((val) => val.x && val.y),
                color: ind.color,
              }));
          } else {
            return [
              {
                data: [],
              },
            ];
          }
        case this.$store.state.type.CODE_YEAR:
        case this.$store.state.type.CODE_COLUMN:
          if (chart.indicators.length) {
            return chart.indicators
              .filter((ind) => ind.title)
              .map((ind) => ({
                name: ind.title,
                data: ind.values.map((val) => val.value),
                color: ind.color,
              }));
          } else {
            return [];
          }
        case this.$store.state.type.CODE_SQUARE:
          if (chart.indicators.length) {
            return chart.indicators
              .filter((ind) => ind.title)
              .map((ind) => ({
                name: ind.title,
                data: ind.values
                  .map((val) => ({
                    x: val.value,
                    y: parseInt(val.year ?? 0),
                  }))
                  .filter((val) => val.x && val.y),
                color: ind.color,
              }));
          } else {
            return [
              {
                data: [],
              },
            ];
          }
        case this.$store.state.type.CODE_PIE:
          if (chart.indicators.length) {
            return chart.indicators
              .filter((ind) => ind.title)
              .map((ind) => {
                const y = ind.values.map((val) => val.value);
                return y[0] ? parseFloat(y[0]) : 0;
              });
          } else {
            return [];
          }
      }
    },
    getChartOptions(chart) {
      const values = chart.indicators.map((ind) => ind.values).flat();
      switch (chart.type.code) {
        case this.$store.state.type.CODE_BUBBLE:
          return {
            chart: {
              height: 350,
              type: "bubble",
              toolbar: {
                show: false,
              },
              fontFamily: "'Montserrat', Arial, Helvetica Neue, Helvetica, sans-serif",
            },
            style: {
              fontSize: "12px",
              fontFamily: "'Montserrat', Arial, Helvetica Neue, Helvetica, sans-serif",
              fontWeight: "normal",
            },
            fill: {
              opacity: 0.8,
              gradient: {
                enabled: false,
              },
            },
            dataLabels: {
              enabled: true,
            },
            xaxis: {
              tickAmount: 12,
              type: "category",
            },
          };
        case this.$store.state.type.CODE_YEAR:
        case this.$store.state.type.CODE_COLUMN:
          return {
            chart: {
              type: "bar",
              height: 350,
              stacked: chart.type.code === this.$store.state.type.CODE_COLUMN,
              toolbar: {
                show: false,
              },
              fontFamily: "'Montserrat', Arial, Helvetica Neue, Helvetica, sans-serif",
            },
            plotOptions: {
              bar: {
                borderRadius: 5,
                dataLabels: {
                  position: "top",
                },
                columnWidth: "60%",
              },
            },
            dataLabels: {
              offsetY: -20,
              enabled: true,
              style: {
                fontSize: "12px",
                fontFamily: "'Montserrat', Arial, Helvetica Neue, Helvetica, sans-serif",
                fontWeight: "normal",
                colors: ["#2A3742"],
              },
              dropShadow: {
                enabled: false,
              },
            },
            xaxis: {
              categories: [
                ...new Set(
                  values
                    .filter((val) => val.year)
                    .map((val) => {
                      //if (val.footnote) {
                      //  return `${val.year} *`;
                      //}
                      return val.year;
                    })
                ),
              ],
            },
            tooltip: {
              enabled: false,
            },
          };
        case this.$store.state.type.CODE_SQUARE:
          return {
            legend: {
              show: false,
            },
            tooltip: {
              enabled: false,
            },
            fill: {
              opacity: 1,
            },
            chart: {
              height: 350,
              type: "treemap",
              toolbar: {
                show: false,
              },
              fontFamily: "'Montserrat', Arial, Helvetica Neue, Helvetica, sans-serif",
            },
            dataLabels: {
              enabled: true,
              style: {
                fontSize: "24px",
                fontFamily: "'Montserrat', Arial, Helvetica Neue, Helvetica, sans-serif",
                fontWeight: "400",
              },
              dropShadow: {
                enabled: false,
                top: 0,
                left: 0,
                blur: 0,
                spread: 1,
                opacity: 1,
              },
            },
          };
        case this.$store.state.type.CODE_PIE:
          return {
            chart: {
              height: 400,
              type: "pie",
              toolbar: {
                show: false,
              },
              fontFamily: "'Montserrat', Arial, Helvetica Neue, Helvetica, sans-serif",
            },
            dataLabels: {
              formatter: function (val) {
                return val;
              },
              enabled: true,
              style: {
                fontSize: "12px",
                fontFamily: "'Montserrat', Arial, Helvetica Neue, Helvetica, sans-serif",
                fontWeight: "normal",
              },
              dropShadow: {
                enabled: false,
              },
            },
            floating: true,
            itemMargin: {
              horizontal: 5,
              vertical: 5,
            },
            legend: {
              show: true,
              position: "bottom",
              fontSize: "12px",
              fontFamily: "'Montserrat', Arial, Helvetica Neue, Helvetica, sans-serif",
              fontWeight: "normal",
              onItemHover: {
                highlightDataSeries: true,
              },
              formatter: function (seriesName, opts) {
                return [
                  seriesName,
                  "<span class='pie__data' style='background:" +
                    opts.w.globals.colors[opts.seriesIndex] +
                    ";'>",
                  opts.w.globals.series[opts.seriesIndex] + "%",
                  "</span>",
                ];
              },
            },
            colors: ["#FF8000", "#309ED0", "#39C86A", "#F5C324", "#FF0000"],
            labels: [...new Set(chart.indicators.filter((val) => val.title).map((val) => val.title))],
          };
      }
    },
  },
  components: {
    ClientOnly,
    VueApexCharts: () => import("vue-apexcharts"),
  },
};
</script>

<style lang="stylus">
.chart-17
  .apexcharts-legend
    overflow initial !important

  .apexcharts-legend-series
    padding 5px

    &:hover
      .pie__data
        margin-bottom 3px
        opacity 1
        pointer-events auto

.pie__data
  absolute left 50% bottom 100%
  padding 5px 10px
  font-size 1em
  transform translate(-50%, 0)
  color @css {rgb(var(--main_white))}
  box-shadow @css {rgba(var(--main_dark), 0.15)} 0 2px 3px
  border-radius 5px
  margin-bottom 0
  opacity 0
  pointer-events none
  transition margin-bottom 0.3s
</style>
